import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="consumer-summary"
const backgroundColors = [
  "#4db6ac",
  "#4dd0e1",
  "#4fc3f7",
  "#42a5f5",
  "#64b5f6",
  "#7986cb",
  "#ce93d8",
  "#f48fb1",
  "#ef9a9a",
  "#b0bec5",
  "#eeeeee",
  "#bcaaa4",
]

export default class extends Controller {
  static targets = [ "chart"]
  static values = { brandRevenue: Array, brandOrder: Array }


  connect() {
    this.brandRevenueChart()
    this.brandOrderChart()
  }

  brandRevenueChart() {
    if (this.brandRevenueValue.length === 0) {
      return
    }

    const dataSets= [{
      data: this.brandRevenueValue.map(brand => brand.total),
      backgroundColor: this.brandRevenueValue.map((_, index) => backgroundColors[index]),
    }]

    this.chart = new Chart(this.chartTarget, {
      type: 'pie',
      data: {
        labels: this.brandRevenueValue.map(brand => brand.label),
        datasets: dataSets
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '品牌營收'
          },
        },
      }
    })
  }

  brandOrderChart() {
    if (this.brandOrderValue.length === 0) {
      return
    }

    const dataSets= [{
      data: this.brandOrderValue.map(brand => brand.count),
      backgroundColor: this.brandOrderValue.map((_, index) => backgroundColors[index]),
    }]

    this.chart = new Chart(this.chartTarget, {
      type: 'pie',
      data: {
        labels: this.brandOrderValue.map(brand => brand.label),
        datasets: dataSets
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '品牌訂單數'
          },
        },
      }
    })
  }
}
